import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ErrorService } from "src/app/DTOs/errors/error.service";
import { ValidatorService } from "src/app/DTOs/validators/Validator";
@Component({
  selector: "app-bayantablereport",
  templateUrl: "./bayantablereport.component.html",
  styleUrls: ["./bayantablereport.component.sass"],
})
export class BayanTableReportComponent implements OnInit {
  // @Input() name: string;
  @Input() data: any;
  constructor(
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private validatorService: ValidatorService,
    private translate: TranslateService,
  ) {
  }
  ngOnInit(): void {
  }

}
