<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="clearfix">
        <!-- <img [src]='room.img' alt="avatar"> -->
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17 font-bold">
            <span class="font-weight-bold p-t-5 font-17 font-bold" *ngIf="lang=='ar'">{{'COMMON.Print'|translate}} {{
              tableName | translate }}</span>
            <span class="font-weight-bold p-t-5 font-17 font-bold" *ngIf="lang=='en'">{{'COMMON.Print'|translate}} {{
              tableFName | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button id="print" type="button" mat-raised-button printSectionId="divPrintId" styleSheetFile="src/styles.scss"
        useExistingCss="false" openNewTab="true" ngxPrint>
        <img src="assets/images/icons/print.svg" alt="">
        {{ 'COMMON.Print' | translate }}
      </button>
      <!-- <button style="margin-right: 10px !important; background: inherit !important;
          box-shadow: none !important;" type="button" mat-raised-button (click)="generatePDF()">
        <mat-icon fontSet="material-icons-outlined" style="font-size: 23px;">picture_as_pdf
        </mat-icon>
      </button> -->
      <button mat-raised-button color="primary" class="mx-3" (click)="saveSettings()">
        {{'ReportBuilder.SaveSettings'|translate}}
        <mat-icon fontSet="material-icons-outlined" style="font-size: 23px;">save</mat-icon>
      </button>
      <button mat-icon-button (click)="visibleForColumns=true">
        <mat-icon fontSet="material-icons-outlined"></mat-icon>
      </button>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="mat-dialog-content" style="max-height: 86vh !important;">
    <p-progressSpinner class="flex" *ngIf="!reportdata"></p-progressSpinner>
    <div *ngIf="reportdata" class="card m-4 bayan-repoer-tools">
      <div class="body">
        <div>
          <div class="row">
            <div class="col-12 mt-2 mb-0 left">
              <ul class="tools">
                <li style="width: 16rem;">
                  <p-dropdown [options]="fontFamily" optionLabel="text" optionValue="value" [filter]="false"
                    emptyFilterMessage="{{'COMMON.NoDataFound' | translate}}"
                    emptyMessage="{{'COMMON.NoDataFound' | translate}}" [showClear]="false"
                    [(ngModel)]="selectedfontFamily" [ngModelOptions]="{standalone: true}" selectedItem="SelectedGroup"
                    required="true" placeholder="{{'ReportBuilder.FontFamily' | translate}}" autoWidth="false"
                    [style]="{'width':'100%'}" class="report-font-family">
                  </p-dropdown>
                </li>

                <li>
                  <p-toggleButton [(ngModel)]="bold" onLabel="B" offLabel="B"
                    (onChange)="bold?selectedfontWeight='bold':selectedfontWeight='200'"></p-toggleButton>
                </li>
                <li>
                  <p-toggleButton class="report-text-align-center" [(ngModel)]="textAlignCenter"
                    onIcon="'pi pi-align-center'" offIcon="pi pi-align-center"
                    (onChange)="textAlignCenter?selectedtextAlign='center':selectedtextAlign='inherit'"></p-toggleButton>
                </li>
                <li>
                  <mat-icon *ngIf="!setgridlines" (click)="setgridlines=true"
                    style="font-size: 20px;">table</mat-icon>
                  <mat-icon *ngIf="setgridlines" fontSet="material-icons-outlined" (click)="setgridlines=false"
                    style="font-size: 20px;">table_rows</mat-icon>
                </li>
                <li>
                  <p-selectButton [options]="printLayout" [(ngModel)]="selectedprintLayout" optionLabel="text"
                    optionValue="value" (onChange)="addPrintStyles()">
                    <ng-template let-item pTemplate>
                      <mat-icon fontSet="material-icons-outlined" style="margin: -1.5px;display: flex;justify-content: center;
            align-items: center;">{{item.text}}</mat-icon>
                    </ng-template>
                  </p-selectButton>
                </li>
                <li>
                  <p-inputNumber [showButtons]="true" [(ngModel)]="fontSize" buttonLayout="horizontal"
                    inputId="horizontal" spinnerMode="horizontal" [step]="1" [min]="10" [max]="35"
                    decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" class="inputNumber">
                  </p-inputNumber>
                </li>
                <li>
                  <button mat-raised-button color="primary" (click)="this.visible = true" style="width: 100%;
                  white-space: normal;">
                    {{'COMMON.VisibleColumnsAndOrder' | translate}}
                    <mat-icon fontSet="material-icons-outlined" class="mat-icon-in-button">sort</mat-icon>
                  </button>
                </li>
                <li>
                  <button mat-raised-button color="primary" (click)="this.visibleHeaderAndFooterSetting = true" style="width: 100%;
                  white-space: normal;">
                    {{'ReportBuilder.Footer' | translate}}
                    <mat-icon fontSet="material-icons-outlined" class="mat-icon-in-button">sort</mat-icon>
                  </button>
                </li>
              </ul>
              <!-- <div class="right">
                <button mat-raised-button color="primary" (click)="saveSettings()">
                  {{'ReportBuilder.SaveSettings'|translate}}
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2"></div>
    <div class="row clearfix">
      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
        <div class="card">

          <div class="body">
            <div id="divPrintId" [ngStyle]="lang=='ar'?{'direction': 'rtl'}:{'direction': 'ltr'}">
              <table style="width: 100%;" [style]="lang=='ar'?'direction:rtl;':'direction:ltr;'">
                <tr *ngIf="lang!='ar'">
                  <th style="width: 40%; font-family: tajawal; font-weight: bold;padding: 5px 10px;"
                    [style]="'COMMON.text-align-invaoice'|translate">
                    <span>{{tanentSettings.f_Company_Name }}</span><br>
                    <span>{{tanentSettings.f_Activity}}</span><br>
                    <span><span>{{tanentSettings.fCity}}</span> - <span>{{tanentSettings.fDistrict}}</span> -
                      <span>{{tanentSettings.fStreet}}</span> </span><br>
                    <span><span>{{tanentSettings.building_No}}</span> - <span>{{tanentSettings.office_No}}</span>
                      -
                      <span>{{tanentSettings.additional_No}}</span> </span><br>
                    <span>CRN : <span>{{tanentSettings.crNo}}</span> </span><br>
                    <span>Mobile : <span>{{tanentSettings.phoneNumber}}</span> </span><br>
                    <span>Tax No : <span>{{tanentSettings.taxNo}}</span> </span>
                    <!-- <p> {{ 'PURCHASEINVOICE.BuildingNo' | translate }}/<span style="margin: 0px 6px;">{{tanentSettings.building_No}}</span> {{ 'PURCHASEINVOICE.FloorNo' | translate }}/<span>{{tanentSettings.floor_No}}</span></p> -->
                  </th>
                  <th style="text-align: center; width: 35%; font-family: tajawal;">
                  </th>
                  <th style="text-align: center;width: 25%;">
                    <img *ngIf="tanentSettings.logoUrl" [src]="tanentSettings.logoUrl"
                      [style]="'width:100px; margin-right:5px;'" alt="avatar">
                  </th>
                </tr>
                <tr *ngIf="lang=='ar'">
                  <th style="width: 25%; font-family: tajawal; font-weight: bold;padding: 5px 10px;"
                    [style]="'COMMON.text-align'|translate">
                    <span>{{lang=='ar'?tanentSettings.companyName:tanentSettings.f_Company_Name }}</span><br>
                    <span>{{lang=='ar'?tanentSettings.activity:tanentSettings.f_Activity}}</span><br>
                    <span><span>{{lang=='ar'?tanentSettings.city:tanentSettings.fCity}}</span> -
                      <span>{{lang=='ar'?tanentSettings.district:tanentSettings.fDistrict}}</span> -
                      <span>{{lang=='ar'?tanentSettings.street:tanentSettings.fStreet}}</span> </span><br>
                    <span><span>{{tanentSettings.building_No}}</span> - <span>{{tanentSettings.office_No}}</span>
                      -
                      <span>{{tanentSettings.additional_No}}</span> </span><br>
                    <span>{{ 'PURCHASEINVOICE.CRN' | translate }}: <span>{{tanentSettings.crNo}}</span>
                    </span><br>
                    <span>{{ 'COMMON.Mobile' | translate }}: <span>{{tanentSettings.phoneNumber}}</span>
                    </span><br>
                    <span>{{ 'PURCHASEINVOICE.VATNO' | translate }}: <span>{{tanentSettings.building_No}}</span>
                    </span>
                    <!-- <p> {{ 'PURCHASEINVOICE.BuildingNo' | translate }}/<span style="margin: 0px 6px;">{{tanentSettings.building_No}}</span> {{ 'PURCHASEINVOICE.FloorNo' | translate }}/<span>{{tanentSettings.floor_No}}</span></p> -->
                  </th>
                  <th style="text-align: center;width: 25%;">
                    <img [src]="tanentSettings.logoUrl" [style]="'width:100px; margin-right:5px;'" alt="avatar">
                  </th>
                  <th style="width: 25%; font-family: tajawal; font-weight: bold;padding: 5px 10px;"
                    [style]="'COMMON.text-align-invaoice'|translate">
                    <span>{{tanentSettings.f_Company_Name }}</span><br>
                    <span>{{tanentSettings.f_Activity}}</span><br>
                    <span><span>{{tanentSettings.fCity}}</span> - <span>{{tanentSettings.fDistrict}}</span> -
                      <span>{{tanentSettings.fStreet}}</span> </span><br>
                    <span><span>{{tanentSettings.building_No}}</span> - <span>{{tanentSettings.office_No}}</span>
                      -
                      <span>{{tanentSettings.additional_No}}</span> </span><br>
                    <span>CRN : <span>{{tanentSettings.crNo}}</span> </span><br>
                    <span>Mobile : <span>{{tanentSettings.phoneNumber}}</span> </span><br>
                    <span>Tax No : <span>{{tanentSettings.taxNo}}</span> </span>
                  </th>
                </tr>
              </table>

              <app-bayandynamic-table *ngIf="action!='details'" [columns]="selectedColumns"
                [tableHeaderAndFooter]="tableHeaderAndFooter" [data]="reportdata" [fontSize]="fontSize"
                [selectedfontWeight]="selectedfontWeight" [selectedfontFamily]="selectedfontFamily"
                [selectedtextAlign]="selectedtextAlign" [setgridlines]="setgridlines"></app-bayandynamic-table>
              <div *ngIf="action=='details'&&selectedColumns">
                <div class="mb-4" [ngStyle]="lang=='ar'?{'direction': 'rtl'}:{'direction': 'ltr'}" style="width: 100%;display: flex;
        flex-wrap: wrap;">
                  <div style="display: inline-block;border: 1px solid #c9c9c9;
          padding: 8px 7px;" *ngFor="let column of getColumnsWithoutChildrenforDetailsHeader(selectedColumns)"
                    [ngStyle]="{'width':column.width+'%'}">
                    <span [ngSwitch]="true">
                      <span *ngSwitchCase="column.type=='boolean'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        <div *ngIf="reportdata[column.columnName]==true"
                          [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                          class="badge col-green">{{ 'COMMON.Yes' | translate }}
                        </div>
                        <div *ngIf="reportdata[column.columnName]==false"
                          [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                          class="badge col-red">{{ 'COMMON.No' | translate }}</div>
                      </span>
                      <span *ngSwitchCase="column.type=='date'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{reportdata[column.columnName]|date:'yyyy-MM-dd'}}
                      </span>
                      <span *ngSwitchCase="column.type=='datetime'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{reportdata[column.columnName]|date:'yyyy-MM-dd \n h:mm a'}}
                      </span>
                      <span *ngSwitchCase="column.type=='time'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{reportdata[column.columnName]|date:'h:mm a'}}
                      </span>
                      <span *ngSwitchCase="column.type=='object'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{splitObject(reportdata,column.columnName)}}
                      </span>
                      <span *ngSwitchCase="column.type=='dateYear'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{reportdata[column.columnName]|date:'yyyy'}}
                      </span>
                      <span *ngSwitchCase="column.type=='enum'">
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        <span
                          *ngIf="reportdata[column.columnName]!=0">{{'Enum.'+reportdata[column.columnName]|translate}}</span>
                        <span *ngIf="reportdata[column.columnName]==0">{{reportdata[column.columnName]}}</span>
                      </span>
                      <span *ngSwitchDefault>
                        <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                        {{reportdata[column.columnName]}}
                      </span>
                    </span>
                  </div>
                </div>
                <div *ngFor="let column of selectedColumns">
                  <!-- <h4 *ngIf="column.columns" style="font-weight: bold;"
            [ngStyle]="{'font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">
            {{column.displayName|translate}}</h4> -->
                  <app-bayandynamic-table *ngIf="column.columns.length>0" [columns]="column.columns"
                    [tableHeaderAndFooter]="tableHeaderAndFooter" [tableName]="column.displayName"
                    [data]="reportdata[column.columnName]" [fontSize]="fontSize"
                    [selectedfontWeight]="selectedfontWeight" [selectedfontFamily]="selectedfontFamily"
                    [selectedtextAlign]="selectedtextAlign" [setgridlines]="setgridlines"></app-bayandynamic-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
        <div class="card invocie" style="border: 1px #b3aeae solid;">

          <div class="body">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <button class="butt-email d-grid w-100 mb-2 waves-effect" mat-raised-button>
                <mat-icon style="font-size: 13px;vertical-align: bottom;">email
                </mat-icon>
                {{'COMMON.Email' | translate }}
              </button>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <button class="butt-whatsapp d-grid w-100 mb-2 waves-effect" mat-raised-button>
                <mat-icon style="font-size: 13px;vertical-align: bottom;">chat
                </mat-icon>
                {{'COMMON.WahatsApp' | translate }}
              </button>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <button id="print" type="button" mat-raised-button printSectionId="divPrintId"
                styleSheetFile="src/styles.scss" useExistingCss="false" openNewTab="true" ngxPrint
                class="butt-download d-grid w-100 mb-2 waves-effect">
                <mat-icon style="font-size: 13px;vertical-align: bottom;">download
                </mat-icon>
                {{'COMMON.Dowmload' | translate }}
              </button>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <button class="butt-cancel d-grid w-100 mb-2 waves-effect" mat-raised-button (click)="onNoClick()">
                <mat-icon style="font-size: 13px;vertical-align: bottom;">
                </mat-icon>
                {{ 'COMMON.Cancel' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4"></div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

    <p-dialog header="{{'COMMON.VisibleColumnsAndOrder'|translate}}" [(visible)]="visible" [modal]="true"
      [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <label class="form-card-tilte">{{'COMMON.ReportName' | translate}}</label>
          <div class="form-card">
            <div class="body row m-2">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{ 'COMMON.Name' | translate }}</mat-label>
                  <input matInput [(ngModel)]="tableName">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{ 'COMMON.FName' | translate }}</mat-label>
                  <input matInput [(ngModel)]="tableFName">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="columnTemplate; context: { $implicit: selectedColumns,name:lang=='ar'?tableName:tableFName }"></ng-container>
    </p-dialog>
  </div>

  <ng-template #columnTemplate let-columns let-name="name">
    <p-orderList [value]="columns" [listStyle]="{'max-height':'30rem'}" header="{{ name | translate }}"
      [dragdrop]="true">
      <ng-template let-column pTemplate="item">
        <div class="product-item">
          <div class="justify-content-between">
            <h5 class="mb-2">{{column.displayName|translate}}</h5>
            <input type="text" class="p-inputtext-sm" placeholder="{{'ReportBuilder.Width'|translate}}" pInputText
              [(ngModel)]="column.width" />
            <mat-icon style="cursor: pointer;" *ngIf="column.isVisible"
              (click)="column.isVisible=false">visibility</mat-icon>
            <mat-icon style="cursor: pointer;" *ngIf="!column.isVisible"
              (click)="column.isVisible=true">visibility_off</mat-icon>
          </div>
        </div>
      </ng-template>
    </p-orderList>

    <ng-container *ngIf="checkHasChildren(columns)">
      <ng-container *ngFor="let column of getColumnsWithChildren(columns)">
        <div class="m-4">
          <ng-container
            *ngTemplateOutlet="columnTemplate; context: { $implicit: column.columns,name:column.displayName }"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>


  <p-dialog header="{{'COMMON.VisibleColumnsAndOrder'|translate}}" [(visible)]="visibleForColumns" [modal]="true"
    [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">
    <div class="row">
      <app-report-column-form [columns]="selectedColumns"></app-report-column-form>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
        <button class="btn-space addDayButton button" type="button" mat-raised-button color="primary"
          (click)="addParent()">{{'ReportBuilder.AddColumn' | translate}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog header="{{'ReportBuilder.Footer'|translate}}" [(visible)]="visibleHeaderAndFooterSetting" [modal]="true"
    [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">
    <div class="row">
      <p-editor [(ngModel)]="tableHeaderAndFooter.footer" [ngModelOptions]="{standalone: true}"
        [style]="{'height':'320px'}"></p-editor>
    </div>
  </p-dialog>
</div>